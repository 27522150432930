.data-treatment {
    display: flex;
    flex-direction: column;
    width: 80vw;
    max-width: 900px;
    margin: 0 auto;
    padding-top: 40px;
    padding-bottom: 200px;
}

.autoTest {
    width: 100vw;
    height: 100vh;
    z-index: 3;
    position: fixed;
    background-color: white;
    left: 0;
    top: 0;
}

.autoTest__btnClose {

}

.btn__autoTest {
    background-color: #ff4757;
    color: white;
    border-radius: 10px;
    outline: none;
    border: 0px;
    margin-right: 10px;
    cursor: pointer;
}

.containerTests {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.tests {
    border-radius: 10px;
    border: 1px solid #a4b0be;
    padding: 30px;
    min-width: 450px;
  }
  
  .tests__case {
    margin-bottom: 20px;
    
  }

  .tests__case__btn {
    background-color: white;
    border-radius: 10px;
    border: 1px solid #a4b0be;
    padding: 14px 20px;
    cursor: pointer;
    width: 100%;
  }
  
  .tests__case__list {
    /* display: none; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 20px;
  
  }

  .autoTest__iframe {
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
  }