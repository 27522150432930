html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.slick-dots.dots-custom {
  bottom: -40px;
}

.slick-dots.dots-custom ::before{
  font-size: 10px;
}

.slick-dots.dots-custom-carousel {
  bottom: 10px;
}

.slick-dots.dots-custom-carousel ::before{

}

.slick-dots.dots-custom-carousel li {
  width: 30px;
  height: 3px;
  opacity: 0.5;
  background-color: white;
}

.slick-dots.dots-custom-carousel li.slick-active {
  opacity: 1;
}

.slick-dots.dots-custom-carousel li button:before {
  display: none;
}
